$shadowImage: rgba(
	$color: #333,
	$alpha: 0.3,
);
$defaultFontColor: #333;
$grey: #444;
$whityGrey: #666;
$grey999: #999;
$whityWhiteGrey: #ccc;
$inversedDefaultFontColor: #eee;

$white: #fff;

$green: #21ba45;
$greenDarker: #15792d;

$blue: #4183c4;
$blueDarker: #30669c;

$yellow: #fbbd08;
$yellowDarker: #c99703;

$orange: #f2711c;
$orangeDarker: #c1540b;

$errorColor: #db2828;
$linkColor: #3366bb;
$backgroundColor: #f5f5f5;
$primaryColor: #bc98e2;
$secondaryColor: #52a8f5;

$facebookColor: #3b5998;
$twitterColor: #2795e9;
$googleColor: #dd4b39;
$vkColor: #4d7198;
$linkedinColor: #1f88be;
$instagramColor: #395c79;
$youtubeColor: red;

$screen-mobile-min: 320px;
$screen-mobile-max: 767px;
$screen-tablet-max: 991px;
$screen-computer-max: 1199px;
