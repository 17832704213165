.BusinessProfileStatisticAdviser {
	p {
		font-size: 2rem;

		b {
			font-size: 1rem;
		}
	}

	.statistics {
		.statistic {
			width: 100%;
			margin: 0 auto 20px !important;
		}
	}
}
