.NewChallenge {
	.DimmeableImage.ui.image {
		display: block;

		img {
			width: 100%;
			min-height: 150px;
			max-height: 150px;
			object-fit: cover;
		}
	}

	.NewChallenge__introduction-text p {
		color: $whityGrey;
	}
}
