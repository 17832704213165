@import "./ContentSearcher/ContentSearcher.scss";
@import "./ContentSearcher/StartUpLandscapeCard/StartUpLandscapeCard.scss";
@import "./ContentSearcher/AdviserLandscapeCard/AdviserLandscapeCard.scss";
@import "./ContentSearcher/IntrapreneurshipLandscapeCard/IntrapreneurshipLandscapeCard.scss";
@import "./ContentSearcher/MarketplaceLandscapeCard/MarketplaceLandscapeCard.scss";
@import "./ContentSearcher/PostulateLandscapeCard/PostulateLandscapeCard.scss";
@import "./ContentSearcher/TalentLandscapeCard/TalentLandscapeCard.scss";
@import "./ContentSearcher/ElearningLandscapeCard/ElearningLandscapeCard.scss";

.Searcher {
	.go-top-button {
		display: block;
		margin: 0 auto;
		position: sticky;
		background-color: transparent;
		top: calc(100vh - 100px);
		border: 0;
		outline: 0;
		transition: 0.3s;
		opacity: 0.6;
		cursor: pointer;

		i {
			background: linear-gradient(45deg, #bc98e2 0%, #52a8f5 100%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}

		&:hover {
			opacity: 1;
		}
	}
}
