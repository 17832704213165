.UnitChallenge {
	.ImageCarousel {
		width: 100%;
		text-align: center;
	}

	.UnitChallenge__definition {
		width: 100%;
		margin: 0 auto;
		padding: 0 30px;
		overflow: hidden;

		& > * {
			margin-bottom: 40px;
		}

		.button__already-applied {
			opacity: 1 !important;
		}

		.UnitChallengeOverview,
		.UnitChallengeTags {
			padding: 0;
			box-sizing: border-box;

			@include computer {
				padding: 0;
			}
		}

		.UnitChallengeOverview {
			h4 {
				margin-top: 0;
				margin-bottom: 25px;
				color: $whityGrey;
			}

			b.marketplace-price-last {
				color: $whityWhiteGrey;
				display: block;
				font-family: $fontRegular;
				font-size: 12px;
				letter-spacing: 1px;
				text-decoration: line-through;
			}

			b.marketplace-price {
				color: $primaryColor;
				font-family: $fontSemiBold;
				font-size: 20px;
				letter-spacing: 1px;

				span {
					color: $green;
					font-size: 14px;
				}
			}
		}

		.UnitChallenge--Mentor-Image {
			height: 150px;

			img {
				width: 100% !important;
				height: 100% !important;
			}
		}

		@include computer {
			width: 1000px;
		}
	}

	.ElearningList .WebinarCard .extra.content,
	.ElearningList .CommonElearningCard .extra.content {
		display: none;
	}
}

.UnitChallengeHeader {
	padding: 2rem 0 0;
	text-align: center;
	display: flex;
	justify-content: space-between;
	align-items: center;

	@include computer {
		width: 970px;
		padding: 3rem 0;
		margin: 0 auto;
	}
}
