$sideBarMaxWidth: 350px;

.MessagesSidebar {
	flex: 0 0 $sideBarMaxWidth;
	height: 100%;
	overflow-y: scroll;

	position: relative;
	z-index: 40;

	border-right: 1px solid $inversedDefaultFontColor;

	@include withoutScrollbar;

	.MessagesSidebar--search-input {
		overflow: hidden;

		border-radius: 5px;

		@include small-box-shadow(#ddd);

		input {
			border: 0 !important;
		}
	}

	.MessagesSidebar-Comment-Group {
		background-color: $white;
		max-width: $sideBarMaxWidth;

		.UnitSidebarMessage {
			border-radius: 5px;
			cursor: pointer;

			transition: 0.3s;

			@include small-box-shadow(#ddd);

			.UnitSidebarMessage-Header-Item {
				display: flex;
				align-items: center;
				justify-content: space-between;

				p {
					font-size: 12px;
				}
			}

			.UnitSidebarMessage-Text-Item {
				display: flex;

				span {
					width: 20px;
					height: 20px;
					text-align: center;

					margin-left: 10px;

					flex-shrink: 0;

					background-color: $primaryColor;
					color: $white;

					line-height: 20px;

					border-radius: 100%;
				}
			}

			&:hover {
				@include small-box-shadow(#ccc);
			}

			&.active {
				border-left: 4px solid $primaryColor;
				@include small-box-shadow(#ddd);

				.UnitSidebarMessage-Text-Item {
					span {
						display: none;
					}
				}
			}
		}
	}
}
