.BusinessAngel {
	.ui.segment {
		border: 0 !important;
		box-shadow: none;
		background-color: transparent;
	}

	.ui.secondary.menu {
		@include large-box-shadow(#e5e5e5);
		background-color: white;
		padding: 1.5rem;
		margin: 0 0 2.5rem 0;

		.item {
			border-top: 2px solid transparent;
			border-bottom: 2px solid transparent;
			padding: 1.5rem;
			color: $whityGrey;
			font-family: $fontMedium;

			&.active {
				background-color: transparent;
				border-top: 2px solid rgba(0, 0, 0, 0.3);
				border-bottom: 2px solid rgba(0, 0, 0, 0.3);
				color: $defaultFontColor;
				border-radius: 0;
			}
		}
	}
}
