.LikeButton {
	span {
		font-size: 14px;
		font-weight: 400;
		color: $whityGrey;
		text-align: center;
	}

	svg {
		cursor: pointer;
		overflow: visible;
		width: 50px;

		#heart {
			transform-origin: center;
			animation: animateHeartOut 0.3s linear forwards;
		}

		#main-circ {
			transform-origin: 29.5px 29.5px;
		}
	}

	.LikeButton--detail {
		&.disabled {
			svg {
				cursor: default !important;
			}
		}
	}

	&.vertical-mode {
		span {
			width: 100%;
			margin: -5px 0 0;
			display: block;
		}

		&.small {
			svg {
				width: 50px;
			}
		}

		&.medium {
			svg {
				width: 70px;
			}
		}

		&.big {
			svg {
				width: 90px;
			}
		}

		&.large {
			svg {
				width: 110px;
			}
		}
	}

	&.horizontal-mode {
		.LikeButton--detail {
			min-width: 100px;
			margin: 0 10px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		span {
			min-width: 30px;
		}

		&.small {
			svg {
				min-width: 50px;
			}
		}

		&.medium {
			min-width: 120px;

			svg {
				min-width: 70px;
			}
		}

		&.big {
			min-width: 140px;

			svg {
				min-width: 90px;
			}
		}

		&.large {
			min-width: 160px;

			svg {
				min-width: 110px;
			}
		}
	}

	#checkbox {
		display: none;
	}

	#checkbox:checked + label svg {
		#heart {
			transform: scale(0.2);
			fill: #e2264d;
			animation: animateHeart 0.3s linear forwards 0.25s;
		}
		#main-circ {
			transition: all 2s;
			animation: animateCircle 0.3s linear forwards;
			opacity: 1;
		}

		#grp1 {
			opacity: 1;
			transition: 0.1s all 0.3s;
			#oval1 {
				transform: scale(0) translate(0, -30px);
				transform-origin: 0 0 0;
				transition: 0.5s transform 0.3s;
			}
			#oval2 {
				transform: scale(0) translate(10px, -50px);
				transform-origin: 0 0 0;
				transition: 1.5s transform 0.3s;
			}
		}
		#grp2 {
			opacity: 1;
			transition: 0.1s all 0.3s;
			#oval1 {
				transform: scale(0) translate(30px, -15px);
				transform-origin: 0 0 0;
				transition: 0.5s transform 0.3s;
			}
			#oval2 {
				transform: scale(0) translate(60px, -15px);
				transform-origin: 0 0 0;
				transition: 1.5s transform 0.3s;
			}
		}
		#grp3 {
			opacity: 1;
			transition: 0.1s all 0.3s;
			#oval1 {
				transform: scale(0) translate(30px, 0px);
				transform-origin: 0 0 0;
				transition: 0.5s transform 0.3s;
			}
			#oval2 {
				transform: scale(0) translate(60px, 10px);
				transform-origin: 0 0 0;
				transition: 1.5s transform 0.3s;
			}
		}
		#grp4 {
			opacity: 1;
			transition: 0.1s all 0.3s;
			#oval1 {
				transform: scale(0) translate(30px, 15px);
				transform-origin: 0 0 0;
				transition: 0.5s transform 0.3s;
			}
			#oval2 {
				transform: scale(0) translate(40px, 50px);
				transform-origin: 0 0 0;
				transition: 1.5s transform 0.3s;
			}
		}
		#grp5 {
			opacity: 1;
			transition: 0.1s all 0.3s;
			#oval1 {
				transform: scale(0) translate(-10px, 20px);
				transform-origin: 0 0 0;
				transition: 0.5s transform 0.3s;
			}
			#oval2 {
				transform: scale(0) translate(-60px, 30px);
				transform-origin: 0 0 0;
				transition: 1.5s transform 0.3s;
			}
		}
		#grp6 {
			opacity: 1;
			transition: 0.1s all 0.3s;
			#oval1 {
				transform: scale(0) translate(-30px, 0px);
				transform-origin: 0 0 0;
				transition: 0.5s transform 0.3s;
			}
			#oval2 {
				transform: scale(0) translate(-60px, -5px);
				transform-origin: 0 0 0;
				transition: 1.5s transform 0.3s;
			}
		}
		#grp7 {
			opacity: 1;
			transition: 0.1s all 0.3s;
			#oval1 {
				transform: scale(0) translate(-30px, -15px);
				transform-origin: 0 0 0;
				transition: 0.5s transform 0.3s;
			}
			#oval2 {
				transform: scale(0) translate(-55px, -30px);
				transform-origin: 0 0 0;
				transition: 1.5s transform 0.3s;
			}
		}
		#grp2 {
			opacity: 1;
			transition: 0.1s opacity 0.3s;
		}
		#grp3 {
			opacity: 1;
			transition: 0.1s opacity 0.3s;
		}
		#grp4 {
			opacity: 1;
			transition: 0.1s opacity 0.3s;
		}
		#grp5 {
			opacity: 1;
			transition: 0.1s opacity 0.3s;
		}
		#grp6 {
			opacity: 1;
			transition: 0.1s opacity 0.3s;
		}
		#grp7 {
			opacity: 1;
			transition: 0.1s opacity 0.3s;
		}
	}

	@keyframes animateCircle {
		40% {
			transform: scale(10);
			opacity: 1;
			fill: #dd4688;
		}
		55% {
			transform: scale(11);
			opacity: 1;
			fill: #d46abf;
		}
		65% {
			transform: scale(12);
			opacity: 1;
			fill: #cc8ef5;
		}
		75% {
			transform: scale(13);
			opacity: 1;
			fill: transparent;
			stroke: #cc8ef5;
			stroke-width: 0.5;
		}
		85% {
			transform: scale(17);
			opacity: 1;
			fill: transparent;
			stroke: #cc8ef5;
			stroke-width: 0.2;
		}
		95% {
			transform: scale(18);
			opacity: 1;
			fill: transparent;
			stroke: #cc8ef5;
			stroke-width: 0.1;
		}
		100% {
			transform: scale(19);
			opacity: 1;
			fill: transparent;
			stroke: #cc8ef5;
			stroke-width: 0;
		}
	}

	@keyframes animateHeart {
		0% {
			transform: scale(0.2);
		}
		40% {
			transform: scale(1.2);
		}
		100% {
			transform: scale(1);
		}
	}

	@keyframes animateHeartOut {
		0% {
			transform: scale(1.4);
		}
		100% {
			transform: scale(1);
		}
	}
}
