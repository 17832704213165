@include justMobile {
	.responsive-tablet:not(.responsive-mobile),
	.responsive-desktop:not(.responsive-mobile) {
		display: none !important;
	}
}

@include justTablet {
	.responsive-mobile:not(.responsive-tablet),
	.responsive-desktop:not(.responsive-tablet) {
		display: none !important;
	}
}

@include justComputer {
	.responsive-mobile:not(.responsive-desktop),
	.responsive-tablet:not(.responsive-desktop) {
		display: none !important;
	}
}
