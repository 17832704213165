@include font-face("Lato-Black", "/fonts/Lato-Black");
@include font-face("Lato-BlackItalic", "/fonts/Lato-BlackItalic");
@include font-face("Lato-Bold", "/fonts/Lato-Bold");
@include font-face("Lato-BoldItalic", "/fonts/Lato-BoldItalic");
@include font-face("Lato-Italic", "/fonts/Lato-Italic");
@include font-face("Lato-Light", "/fonts/Lato-Light");
@include font-face("Lato-LightItalic", "/fonts/Lato-LightItalic");
@include font-face("Lato-Regular", "/fonts/Lato-Regular");
@include font-face("Lato-Thin", "/fonts/Lato-Thin");
@include font-face("Lato-ThinItalic", "/fonts/Lato-ThinItalic");

$fontBlack: "Lato-Black";
$fontBlackItalic: "Lato-BlackItalic";
$fontBold: "Lato-Bold";
$fontBoldItalic: "Lato-BoldItalic";
$fontSemiBold: "Lato-Bold";
$fontSemiBoldItalic: "Lato-BoldItalic";
$fontItalic: "Lato-Italic";
$fontLight: "Lato-Light";
$fontLightItalic: "Lato-LightItalic";
$fontRegular: "Lato-Regular";
$fontMedium: "Lato-Regular";
$fontThin: "Lato-Thin";
$fontThinItalic: "Lato-ThinItalic";
