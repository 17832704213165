.CardPlans {
	.price {
		font-size: 36px !important;

		.symbol {
			font-size: 20px;
			color: $whityWhiteGrey;
		}
	}

	.meta {
		h4 {
			color: $grey999;
		}
	}

	.description {
		min-height: 160px;
	}
}
