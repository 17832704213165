.Comments {
	.UnitComment {
		&.UnitComment--sending {
			opacity: 0.3;
		}

		.avatar {
			width: 2.5em !important;
			height: 2.5em !important;

			img {
				object-fit: cover;
			}
		}
	}

	.CommentsReplySection {
		position: relative;

		.CommentsReplySection--wrapper-box {
			width: 100%;
			display: flex;

			.CommentsReplySection--wrapper-textarea {
				width: 100%;
				flex: 1;
				margin-left: 10px;
			}

			img {
				width: 2.5em !important;
				height: 2.5em !important;
				margin-top: 10px;
				object-fit: cover;
				border-radius: 0.25rem;
			}
		}
	}

	.Comments--wrapper-loader {
		width: 100%;
		position: relative;
	}

	.Comments--wrapper-load-more {
		width: 100%;
		position: relative;
	}
}
