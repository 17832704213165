.ElearningLandscapeCardDesktop {
	$sideSquare: 100px;

	.ElearningLandscapeCardDesktop--image {
		overflow: hidden;
		display: inline-block;
		vertical-align: middle;

		img {
			width: $sideSquare;
			height: $sideSquare !important;
			object-fit: cover;
		}
	}

	.ElearningLandscapeCardDesktop--intro {
		width: auto !important;
		margin: 0 !important;
		display: inline-block;
		vertical-align: middle;
	}

	.ElearningLandscapeCardDesktop--features {
		width: 100%;
		display: flex;
		align-items: flex-end;

		& > * {
			flex: 3;
		}

		.ElearningLandscapeCardDesktop--wrapper-like-button {
			flex: 0;
			margin-right: 20px;
			display: flex;
			align-items: center;
		}
	}
}

.ElearningLandscapeCardMobile {
	.ElearningLandscapeCardMobile--ClickableImage {
		width: 100%;

		img {
			height: 150px !important;
			object-fit: cover;
		}
	}
}
