.LinkProfile {
	width: 100%;
	@include transition("opacity 0.3s linear!important");
	@include ellipsis;

	&:hover {
		opacity: 0.4;
		cursor: pointer;
	}
}
