.InternalFormTextarea,
.SimpleInternalFormTextarea {
	width: 100%;

	&.InternalFormTextarea-without-padding {
		.ql-editor {
			padding: 0;
		}
	}

	&.InternalFormTextarea-without-scrolling {
		.ql-editor {
			height: auto;
		}

		.ql-container {
			&.ql-snow {
				border: 0 !important;
			}
		}
	}

	&.InternalFormTextarea-editable {
		.ql-editor {
			height: 200px !important;
		}

		.ql-container {
			height: auto !important;
		}
	}
}
