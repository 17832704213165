.BusinessProfile {
	.business-profile-background {
		height: 400px;
		@include small-box-shadow(rgba(0, 0, 0, 0.3));
		background-color: white;
	}

	.business-profile-info {
		top: 0px;

		.business-profile-info-column {
			@include medium-box-shadow(rgba(0, 0, 0, 0.3));
			z-index: 99;
			background-color: white;

			.business-profile-info-image {
				height: 400px;
				background-color: white;
			}
		}

		.business-profile-followers {
			.item {
				left: -400px;
				margin-left: -10px !important;

				& > img {
					object-fit: cover;
				}
			}
		}
	}

	.ui.menu {
		padding: 0.5rem !important;
	}

	@include computer {
		top: -250px;

		.ui.menu {
			padding: 1.5rem !important;
		}
	}
}
