@import "./stylesheet/const.scss";
@import "./stylesheet/mixins.scss";
@import "./stylesheet/fonts.scss";
@import "./stylesheet/breakpoints/index.scss";

// Elements
@import "./elements/Button/BaseboardButton/BaseboardButton.scss";
@import "./elements/Button/PrimaryButton/PrimaryButton.scss";
@import "./elements/Button/SecondaryButton/SecondaryButton.scss";
@import "./elements/Button/TertiaryButton/TertiaryButton.scss";
@import "./elements/HeroImage/HeroImage.scss";
@import "./elements/ImagePicker/ImagePicker.scss";
@import "./elements/ImagesPicker/ImagesPicker.scss";
@import "./elements/Slider/Slider.scss";
@import "./elements/WhiteWrapper/WhiteWrapper.scss";
@import "./elements/InternalDatepicker/InternalDatepicker.scss";
@import "./elements/InternalFormTextarea/InternalFormTextarea.scss";

// Components
// BusinessProfile
@import "./components/BusinessProfile/BusinessProfile";
// Elearning
@import "./components/Elearning/CommonElearningCard/CommonElearningCard.scss";
@import "./components/Elearning/WebinarCard/WebinarCard.scss";
@import "./components/Elearning/MinimalElearningList/MinimalElearningList.scss";
@import "./components/Elearning/ElearningQuickView/ElearningQuickView.scss";
// Forms
@import "./components/Forms/BusinessProfileStatisticForm/BusinessProfileStatisticForm.scss";
@import "./components/Forms/BusinessProfileAdviserStatisticForm/BusinessProfileAdviserStatisticForm.scss";
@import "./components/Forms/Field/Field.scss";
@import "./components/Forms/FormInfoBusinessProfile/FormInfoBusinessProfile.scss";
// Profile
@import "./components/Profile/LabelListProfile/LabelListProfile.scss";
@import "./components/Profile/LinkProfile/LinkProfile.scss";
@import "./components/Profile/RecommendationsProfile/RecommendationsProfile.scss";
@import "./components/Profile/LabelAdviserSkillsListsProfile/LabelAdviserSkillsListsProfile.scss";
@import "./components/Profile/LabelSkillsListsProfile/LabelSkillsListsProfile.scss";
@import "./components/Skills/Skills.scss";
@import "./components/UsersList/UserList.scss";
@import "./components/Profile/InfoProfile/InfoProfile";
// Searcher
@import "./components/Searcher/HeaderSearcher/HeaderSearcher.scss";
@import "./components/Searcher/FilterSearcher/FilterSearcher.scss";
// Talent
@import "./components/Talent/TalentCard/TalentCard.scss";
@import "./components/Talent/ApplierCard/ApplierCard.scss";
// Marketplace
@import "./components/Marketplace/MarketplaceCard/MarketplaceCard.scss";
// Interaction
@import "./components/Interactions/LikeButton/LikeButton.scss";
@import "./components/Interactions/CommentCounter/CommentCounter.scss";
@import "./components/Interactions/Comments/Comments.scss";

// Containers
@import "./containers/BusinessProfile/Accelerator/Accelerator.scss";
@import "./containers/BusinessProfile/Adviser/Adviser.scss";
@import "./containers/BusinessProfile/BusinessProfile.scss";
@import "./containers/BusinessProfile/BussinessAngel/BussinessAngel.scss";
@import "./containers/BusinessProfile/Company/Company.scss";
@import "./containers/NewElearning/NewElearning.scss";
@import "./containers/NewWebinar/NewWebinar.scss";
@import "./containers/BusinessProfile/Incubator/Incubator.scss";
@import "./containers/BusinessProfile/Institution/Institution.scss";
@import "./containers/BusinessProfile/University/University.scss";
@import "./containers/BusinessProfile/Startup/Startup.scss";
@import "./containers/Header/Header.scss";
@import "./containers/Footer/Footer.scss";
@import "./containers/Modals/AboutProfileModal/AboutProfileModal.scss";
@import "./containers/Modals/AdviserSkillsProfileModal/AdviserSkillsProfileModal.scss";
@import "./containers/Modals/InfoBusinessProfileModal/InfoBusinessProfileModal.scss";
@import "./containers/Modals/SocialBusinessProfileModal/SocialBusinessProfileModal.scss";
@import "./containers/Modals/SocialProfileModal/SocialProfileModal.scss";
@import "./containers/Modals/DocumentUploaderModal/DocumentUploaderModal.scss";
@import "./containers/Profile/Profile.scss";
@import "./containers/UnitElearning/UnitElearning.scss";
@import "./containers/TalentDashboard/TalentDashboard.scss";
@import "./containers/TendersTalentDashboard/TendersTalentDashboard.scss";
@import "./containers/MarketplaceDashboard/MarketplaceDashboard.scss";
@import "./containers/ChallengeDashboard/ChallengeDashboard.scss";
@import "./containers/UnitTalent/UnitTalent.scss";
@import "./containers/UnitMarketplace/UnitMarketplace.scss";
@import "./containers/NewTalent/NewTalent.scss";
@import "./containers/NewChallenge/NewChallenge.scss";
@import "./containers/AppliersTalent/AppliersTalent.scss";
@import "./containers/Searcher/Searcher.scss";
@import "./containers/Settings/Settings.scss";
@import "./containers/Help/Help.scss";
@import "./containers/UnitChallenge/UnitChallenge.scss";
@import "./containers/Notifications/Notifications.scss";
@import "./containers/Searcher/Users/UsersSearcher.scss";
@import "./containers/Pricing/Pricing.scss";
@import "./containers/MyNetwork/MyNetwork.scss";
@import "./containers/Messages/Messages.scss";

// External Resources
@import "../node_modules/react-toastify/dist/ReactToastify.min.css";

body,
html {
	padding: 0;
	margin: 0;
}

body {
	background-color: $backgroundColor;
	position: relative;
	z-index: -99;
	min-height: 100vh;
}

#__next {
	min-height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $fontBold;
	color: $defaultFontColor;
}

.loading-page-vinglet-logo {
	width: 300px;
}

@include generateSequentialProperty("padding", 0, 20, "left");
@include generateSequentialProperty("padding", 0, 20, "right");
@include generateSequentialProperty("padding", 0, 20, "top");
@include generateSequentialProperty("padding", 0, 20, "bottom");
@include generateSequentialProperty("padding", 0, 20, "y");
@include generateSequentialProperty("padding", 0, 20, "x");
@include generateSequentialProperty("padding", 0, 20, "all");

@include generateSequentialProperty("margin", 0, 20, "left");
@include generateSequentialProperty("margin", 0, 20, "right");
@include generateSequentialProperty("margin", 0, 20, "top");
@include generateSequentialProperty("margin", 0, 20, "bottom");
@include generateSequentialProperty("margin", 0, 20, "y");
@include generateSequentialProperty("margin", 0, 20, "x");
@include generateSequentialProperty("margin", 0, 20, "all");

p {
	font-family: $fontRegular !important;
	font-size: 1rem;
	color: $defaultFontColor;
}

.classified-text {
	background-color: $inversedDefaultFontColor;
	color: $inversedDefaultFontColor;
}

li {
	list-style: none !important;
}

.position-relative {
	position: relative;
}

button.clean-button {
	height: 100%;
	background-color: transparent;
	text-align: left;
	padding: 0;
	margin: 0;
	border: 0;
}

button.disabled {
	background-color: $defaultFontColor;
}

.font-semi-bold {
	font-family: $fontSemiBold !important;
}

.font-semi-bold-italic {
	font-family: $fontSemiBoldItalic !important;
}

.font-color-grey {
	color: $grey !important;
}

.font-color-white-grey {
	color: $whityGrey !important;
}

.font-color-primary {
	color: $primaryColor;
}

.font-color-white {
	color: $inversedDefaultFontColor;
}

.font-color-link {
	color: $linkColor;
}

.font-color-secondary {
	color: $secondaryColor;
}

.font-color-error {
	color: $errorColor;
}

.font-uppercase {
	text-transform: uppercase;
}

.font-capitalize {
	text-transform: capitalize;
}

.font-capitalize-important {
	text-transform: capitalize !important;
}

.font-overflow-ellipsis {
	white-space: normal;
	overflow: hidden;
	text-overflow: ellipsis;
}

.overflow-hidden {
	overflow: hidden;
}

.hide {
	display: none !important;
}

.contents {
	display: contents !important;
}

.flex {
	display: flex !important;
}

.inline {
	display: inline !important;
}

.inline-block {
	display: inline-block !important;
}

.block {
	display: block !important;
}

.text-centered {
	text-align: center;
}

.text-right {
	text-align: right;
}

.text-left {
	text-align: left;
}

.text-centered-important {
	text-align: center !important;
}

.inline-block {
	vertical-align: top;
	display: inline-block !important;
}

.middle-inline-block {
	vertical-align: middle !important;
	display: inline-block !important;
}

.border-zero {
	border: 0 !important;
}

.border-radius-zero {
	border-radius: 0 !important;
}

.border-radius-100 {
	border-radius: 100% !important;
}

.shadow-zero {
	box-shadow: none !important;
}

.full-width {
	width: 100% !important;
	max-width: 100% !important;
}

.full-height {
	height: 100% !important;
	max-height: 100% !important;
}

.full-screen-height {
	height: 100% !important;
	min-height: 100vh !important;
}

.centered-align {
	margin: 0 auto !important;
}

.break-words {
	word-break: break-word;
}

.position-absolute-corner-bottom-right {
	position: absolute;
	bottom: 20px;
	right: 40px;
}

.truncate-ellipsis {
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.with-pointer {
	cursor: pointer;
}

/** HARDCODEDD **/
.ui.input.error > input {
	background-color: #fff6f6 !important;
	border-color: #e0b4b4 !important;
	color: #9f3a38 !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}

.z-index-99 {
	z-index: 99;
}

.z-index-200 {
	z-index: 200;
}

.secondary.menu {
	overflow-x: scroll;
}

img {
	object-fit: cover;
}

.modal {
	top: 20px;

	@include computer {
		top: auto;
	}
}

.loader.font-color-grey {
	&::after {
		border-color: $grey transparent transparent !important;
	}
}

.WrapperFooter {
	& > *:not(.Footer) {
		min-height: calc(100vh - 130px);
	}
}
