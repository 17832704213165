@mixin font-face($family, $filename) {
	@font-face {
		font-family: $family;
		font-display: swap;
		src: url("#{$filename}.ttf") format("truetype");
	}
}

@mixin css3-prefix($prop, $value) {
	-webkit-#{$prop}: #{$value};
	-moz-#{$prop}: #{$value};
	-ms-#{$prop}: #{$value};
	-o-#{$prop}: #{$value};
	#{$prop}: #{$value};
}

@mixin withoutScrollbar {
	-ms-overflow-style: none; /* Internet Explorer 10+ */
	scrollbar-width: none; /* Firefox */

	&::-webkit-scrollbar {
		display: none; /* Safari and Chrome */
	}
}

@mixin vertical-gradient($from, $to, $stop: 100%) {
	background: $to;
	background: -webkit-gradient(linear, left, right, color-stop(0%, $from), color-stop($stop, $to));
	@include css3-prefix("background", linear-gradient(to right, $from 0%, $to $stop));
	filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#{$from}, endColorstr=#{$to});
}

@mixin crossed-gradient($from, $to, $stop: 100%) {
	background: $to;
	background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, $from), color-stop($stop, $to));
	@include css3-prefix("background", linear-gradient(45deg, $from 0%, $to $stop));
	filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#{$from}, endColorstr=#{$to});
}

@mixin crossed-border-gradient($from, $to, $stop: 100%) {
	border-image: $to;
	border-image: -webkit-gradient(linear, left bottom, right top, color-stop(0%, $from), color-stop($stop, $to));
	@include css3-prefix("border-image", linear-gradient(45deg, $from 0%, $to $stop));
}

@mixin crossed-border-radial-gradient($from, $to, $stop: 100%) {
	background-image: $to;
	@include css3-prefix(
		"background-image",
		"linear-gradient(white, white), radial-gradient(circle at top left, " + $from + "," + $to + ")"
	);
}

@mixin font-gradient($from, $to, $stop: 100%) {
	@include crossed-gradient($from, $to);
	@include css3-prefix("background-clip", text);
	@include css3-prefix("text-fill-color", transparent);
}

@mixin small-box-shadow($color, $strict: false) {
	@if $strict {
		@include css3-prefix("box-shadow", $color + " 2px 2px 8px 0!important");
	} @else {
		@include css3-prefix("box-shadow", $color + " 2px 2px 8px 0");
	}
}

@mixin medium-box-shadow($color, $strict: false) {
	@if $strict {
		@include css3-prefix("box-shadow", $color + " 5px 5px 14px 0!important");
	} @else {
		@include css3-prefix("box-shadow", $color + " 5px 5px 14px 0");
	}
}

@mixin large-box-shadow($color, $strict: false) {
	@if $strict {
		@include css3-prefix("box-shadow", $color + " 5px 5px 20px 0!important");
	} @else {
		@include css3-prefix("box-shadow", $color + " 5px 5px 20px 0");
	}
}

@mixin transition($args...) {
	@include css3-prefix("transition", $args);
}

@mixin ellipsis {
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

@mixin generateSequentialProperty($property, $from, $to, $side) {
	@for $i from $from to $to {
		.#{$property}-#{$i}-#{$side} {
			@if $side == "y" {
				#{$property}-top: #{$i}rem !important;
				#{$property}-bottom: #{$i}rem !important;
			} @else if $side == "x" {
				#{$property}-left: #{$i}rem !important;
				#{$property}-right: #{$i}rem !important;
			} @else if $side == "all" {
				#{$property}: #{$i}rem !important;
			} @else {
				#{$property}-#{$side}: #{$i}rem !important;
			}
		}
		.#{$property}-#{$i}_5-#{$side} {
			@if $side == "y" {
				#{$property}-top: #{$i + ".5"}rem !important;
				#{$property}-bottom: #{$i + ".5"}rem !important;
			} @else if $side == "x" {
				#{$property}-left: #{$i + ".5"}rem !important;
				#{$property}-right: #{$i + ".5"}rem !important;
			} @else if $side == "all" {
				#{$property}: #{$i + ".5"}rem !important;
			} @else {
				#{$property}-#{$side}: #{$i + ".5"}rem !important;
			}
		}
	}
}

@mixin fullWidth {
	width: 100%;
	height: 100%;
	position: relative;
}

@mixin fullWidthAbsolute {
	width: 100%;
	height: 100%;
	position: absolute !important;
}

@mixin imageBackground {
	@include fullWidth;
	object-fit: cover;
	z-index: -1;
}

@mixin textBackground {
	position: relative;
	z-index: 1;
}

@mixin responsiveSquare($width) {
	width: $width;

	&:after {
		content: "";
		display: block;
		padding-bottom: 100%;
	}
}

// Small devices
@mixin mobile {
	@media (min-width: #{$screen-mobile-min}) {
		@content;
	}
}

@mixin justMobile {
	@media (max-width: #{$screen-mobile-max}) {
		@content;
	}
}

// Medium devices
@mixin tablet {
	@media (min-width: #{$screen-mobile-max}) {
		@content;
	}
}

@mixin justTablet {
	@media (min-width: #{$screen-mobile-max}) and (max-width: #{$screen-tablet-max}) {
		@content;
	}
}

// Large devices
@mixin computer {
	@media (min-width: #{$screen-tablet-max}) {
		@content;
	}
}

@mixin justComputer {
	@media (min-width: #{$screen-tablet-max}) {
		@content;
	}
}

// Extra large devices
@mixin largeScreens {
	@media (min-width: #{screen-computer-max}) {
		@content;
	}
}
