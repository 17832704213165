.CommentCounter {
	color: #aab8c2;
	// We've put this fixed pixels because it was decided taking in mind the LikeButton (vertical)
	margin: 13px 11px 0px 11px;
	text-align: center;

	span {
		font-size: 14px;
		font-weight: 400;
		color: $whityGrey;
		text-align: center;
	}

	&.vertical-mode {
		span {
			width: 100%;
			margin-top: calc(1rem + 1px);
			display: block;
		}
	}

	&.horizontal-mode {
		.CommentCounter--detail {
			min-width: 100px;
			margin: 0 10px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		span {
			min-width: 30px;
		}

		&.medium {
			min-width: 120px;
		}

		&.big {
			min-width: 140px;
		}

		&.large {
			min-width: 160px;
		}
	}
}
