.UserList {
	& > .ui.card {
		box-shadow: none !important;
	}

	.ui.mini.image {
		height: 35px;
		object-fit: cover;
	}
}
