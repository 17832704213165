.PrimaryButton {
	&.ui.button {
		@include crossed-gradient($primaryColor, $secondaryColor);
		color: white;

		&.animated-button {
			@include transition("opacity 0.3s");

			&:hover {
				opacity: 0.6;
			}
		}
	}
}
