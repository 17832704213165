$maxChatListWidth: 800px;
$maxUnitChat: 60%;
$maxConversationChatBox: 800px;

.UnitConversation {
	width: 100%;
	display: flex;
	flex-direction: column;
	position: relative;
	overflow: hidden;

	z-index: 10;

	header {
		.card {
			border-radius: 0;
		}
	}

	.UnitConversationChatsList {
		flex: 1;
		width: 100%;
		max-width: $maxChatListWidth;

		display: flex;
		flex-direction: column-reverse;

		margin: 0 auto;
		padding-bottom: 100px;
		overflow-y: scroll;

		@include withoutScrollbar;

		.UnitConversationChatsList--MyChat {
			padding: 0;

			text-align: right;

			display: flex;
			flex-direction: column;
			align-items: flex-end;

			li {
				max-width: $maxUnitChat;

				border-radius: 10px 0 10px 10px;

				font-size: 14px;

				background: rgb(125, 52, 205);
				background: $secondaryColor;

				color: $white;
			}
		}

		.UnitConversationChatsList--HisHerChat {
			padding: 0;

			text-align: left;

			display: flex;
			flex-direction: column;
			align-items: flex-start;

			li {
				max-width: $maxUnitChat;

				border-radius: 10px;

				font-size: 14px;

				background-color: $backgroundColor;
				color: $grey;
			}
		}
	}

	.UnitConversationWriteBox {
		width: 100%;
		max-width: $maxConversationChatBox;

		margin: 0 auto;
		border-radius: 20px;

		box-sizing: border-box;

		background-color: white;

		display: flex;
		justify-content: center;
		align-items: stretch;

		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;

		@include medium-box-shadow(#ccc);

		.UnitConversation--Chat-Textarea {
			width: 100%;
			max-height: 200px;

			padding-top: 15px;
			padding-bottom: 15px;

			border-bottom-left-radius: 20px;
			border-top-left-radius: 20px;

			overflow-y: scroll;
			position: relative;
			z-index: 20;

			background-color: transparent;

			@include withoutScrollbar;

			textarea {
				width: 100%;
				max-height: 20px;

				display: inline-table;
				box-sizing: border-box;

				padding: 0;
				outline: 0;
				border: 0;

				font-size: 14px;

				@include withoutScrollbar;
			}
		}

		.UnitConversation--Button-To-Send {
			width: 150px;
			min-height: 100%;
			border-radius: 0 20px 20px 0;
			transition: 0.3s;

			&:not([disabled]) {
				background: $primaryColor !important;
			}
		}
	}
}
