.NewInvitation {
	.NewInvitation--Content {
		display: flex;
		align-items: center;
		justify-content: stretch;

		.NewInvitation--UserInfo {
			flex: 1;
		}

		.NewInvitation--Tools {
			max-width: 300px;
			display: flex !important;
			align-items: center;
			justify-content: flex-end;
		}
	}
}
