.Slider {
	.FieldInput input {
		-webkit-appearance: none;
		width: 100%;
		height: 7px;
		border-radius: 5px;
		background: #d3d3d3;
		outline: none;
		opacity: 0.5;
		-webkit-transition: 0.2s;
		transition: opacity 0.2s;

		&::-webkit-slider-thumb {
			-webkit-appearance: none;
			appearance: none;
			width: 25px;
			height: 25px;
			border-radius: 50%;
			background: $primaryColor;
			cursor: pointer;
			@include small-box-shadow(rgba(0, 0, 0, 0.3));
		}

		&::-moz-range-thumb {
			width: 25px;
			height: 25px;
			border-radius: 50%;
			background: $primaryColor;
			cursor: pointer;
			@include small-box-shadow(rgba(0, 0, 0, 0.3));
		}
	}
}
