.TalentDashboard {
	& > header {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		padding: 1rem 2rem;

		a {
			padding: 10px 3rem;
			display: block;
			margin-right: 10px;
			transition: 0.3s;
			color: white;
			@include small-box-shadow(#ccc);

			&:first-child {
				background-color: $primaryColor;
			}

			&:nth-child(2) {
				background-color: $whityGrey;
			}

			&:hover {
				opacity: 0.6;
			}
		}

		.TalentDashboard__dropdown-talents {
			padding-top: 12px !important;
			padding-bottom: 12px !important;
			margin-right: 10px;
			border-radius: 0 !important;
		}

		@include computer {
			padding: 3rem 5rem;
			flex-direction: row;
		}
	}

	.TalentDashboardHeader {
		.TalentDashboardHeader--buttons {
			display: flex;
			align-items: center;
		}
	}

	.TalentDashboard__selection-process {
		width: 100%;
		max-width: 800px;
		padding: 0 2rem;
		margin: 20px auto;
	}
}
