.ElearningQuickView {
	p {
		color: $grey;
	}

	h4 {
		margin-top: 0;
	}

	.overview-item {
		display: initial;
	}
}
