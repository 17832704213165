.UsersSearcher {
	max-width: 1200px;
	margin: 0 auto;

	.card .image img {
		width: 100% !important;
		height: 200px !important;
		object-fit: cover;
	}
}
