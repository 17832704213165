.ChallengeDashboard {
	& > header {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		padding: 1rem 2rem;

		a {
			display: block;
			margin-right: 10px;
			transition: 0.3s;
			color: white;

			&.create-new {
				background-color: $primaryColor;
			}

			&:hover {
				opacity: 0.6;
			}
		}

		.challenge-dashboard-buttons {
			display: flex;
			align-items: center;

			button.DashboardHeader--configuration-buttons {
				margin-right: 10px;
				transition: 0.3s;
				color: white;
				border-radius: 0;
				@include small-box-shadow(#ccc);
				background-color: $whityGrey;

				&:hover {
					opacity: 0.6;
				}
			}
		}

		.ChallengeDashboard__dropdown-talents {
			padding-top: 12px !important;
			padding-bottom: 12px !important;
			margin-right: 10px;
			border-radius: 0 !important;
		}

		@include computer {
			padding: 3rem 5rem;
			flex-direction: row;
		}
	}

	.challenge-link-dashboard {
		display: none !important;
	}

	.ChallengeDashboard__selection-process {
		width: 100%;
		max-width: 800px;
		padding: 0 2rem;
		margin: 20px auto;
	}
}
