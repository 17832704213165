@import "MessagesSidebar/MessagesSidebar";
@import "UnitConversation/UnitConversation";

.Messages {
	width: 100%;
	height: calc(100vh - 90px);
	max-height: calc(100vh - 90px);

	display: flex;

	background-color: $white;
}
