.WebinarCard {
	.WebinarCard__background-image {
		height: 150px !important;
		object-fit: cover;
	}

	.WebinarCard__title,
	.WebinarCard__description {
		text-overflow: ellipsis;
		overflow: hidden;
	}
}
