.HeaderSearcher {
	$heightHeader: 300px;

	height: $heightHeader;
	position: relative;

	.header-searcher-background-img {
		width: 100%;
		position: absolute;
		max-height: inherit;
		z-index: -1;

		img {
			width: 100%;
			max-height: $heightHeader;
			object-fit: cover;
			@include css3-prefix("filter", "brightness(50%);");
		}
	}

	h2 {
		color: white;
		line-height: $heightHeader;
	}

	input {
		width: 600px;
	}

	.SecondaryButton {
		width: 200px;
	}
}
