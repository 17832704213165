.UnitElearning {
	margin-top: 1rem;

	.UnitElearningHeader {
		width: 100%;
		height: 300px;
		min-height: 300px;
		overflow: hidden;

		.UnitElearningHeader--background {
			height: 100%;
			background-color: rgba(0, 0, 0, 0.4);
			color: white;

			h2 {
				color: white;
			}
		}

		.UnitElearningHeader-blurImage {
			width: 100%;
			height: inherit;
			position: absolute;
			top: 0;
			left: 0;
			filter: blur(10px);
			z-index: -1;
		}

		.UnitElearningHeader--backgroundImage {
			height: 100%;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				background-color: white;
			}
		}
	}

	.UnitElearningMenuBar {
		width: 100%;
	}

	.UnitElearning__WrapperElearningQuickView,
	.UnitElearningDescription {
		padding: 0 3rem !important;
		margin-top: 1rem;

		@include computer {
			padding: 0 1rem !important;
			margin-top: 2rem;
		}
	}

	.UnitElearningDescription {
		@include computer {
			padding: 0 4rem !important;
		}
	}

	.UnitElearningFooter {
		background-color: $inversedDefaultFontColor;

		.button_subscription {
			opacity: 1 !important;
		}

		.button_subscription,
		.button_subscribe {
			margin-bottom: 1rem;

			@include computer {
				margin-right: 1rem;
				margin-bottom: 0;
			}
		}

		@include computer {
			padding: 4rem 0;
		}
	}

	.LabelListProfile {
		padding: 0;
	}

	.UnitElearningRelatedContent {
		.UnitElearningRelatedContent--Image {
			width: 100%;
			height: 200px;

			img {
				width: 100% !important;
				height: 100% !important;
			}
		}
	}
}
