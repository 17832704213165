.SocialBusinessProfileModal {
	.ui.input {
		flex-direction: column;

		.ui.label {
			color: white;
			width: 200px;
			text-align: right;

			& + input {
				width: 100% !important;
			}

			@include computer {
				& + input {
					width: auto !important;
				}
			}
		}

		@include computer {
			flex-direction: row;
		}
	}

	.facebook .ui.label {
		background-color: $facebookColor;
	}

	.twitter .ui.label {
		background-color: $twitterColor;
	}

	.linkedin .ui.label {
		background-color: $linkedinColor;
	}

	.instagram .ui.label {
		background-color: $instagramColor;
	}

	.youtube .ui.label {
		background-color: $youtubeColor;
	}

	.website .ui.label {
		background-color: $grey;
	}
}
