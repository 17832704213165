.TalentCard {
	& > .ui.image {
		height: 150px !important;
		object-fit: cover;
	}

	.TalentCard__title {
		text-overflow: ellipsis;
		overflow: hidden;
	}

	@include computer {
		margin: 1rem;

		.content {
			padding: 1rem;
		}
	}
}
