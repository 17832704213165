.MarketplaceLandscapeCard {
	$sideSquare: 100px;

	.pvp-price {
		font-size: 13px;
		text-decoration: line-through;
	}

	.MarketplaceLandscapeCard--image {
		overflow: hidden;
		display: inline-block;
		vertical-align: middle;

		img {
			width: $sideSquare;
			height: $sideSquare !important;
			object-fit: cover;
		}
	}

	.MarketplaceLandscapeCard--intro {
		width: auto !important;
		margin: 0 !important;
		display: inline-block;
		vertical-align: middle;
	}

	.MarketplaceLandscapeCardDesktop--features {
		width: 100%;
		display: flex;
		align-items: flex-end;

		& > * {
			flex: 3;
		}

		.MarketplaceLandscapeCardDesktop--wrapper-like-button {
			flex: 0;
			margin-right: 20px;
			display: flex;
			align-items: center;
		}
	}
}
