@import "./card-plans/CardPlans.scss";
@import "pricing-feature/PricingFeatures";

.Pricing {
	min-height: calc(100vh - 130px);

	position: relative;

	transition: 0.3s;

	ul.Pricing--SelectProduct {
		width: 100%;

		margin: 0;
		padding: 0;

		display: flex;
		justify-content: center;

		li {
			display: flex;

			justify-content: center;
			align-items: center;

			cursor: pointer;
			color: $defaultFontColor;

			&.Pricing--SelectedProduct {
				background-color: rgba(255, 255, 255, 1);

				& > p {
					color: $whityGrey;
				}
			}

			&:not(.Pricing--SelectedProduct):hover {
				background-color: rgba(255, 255, 255, 0.3);
			}
		}
	}

	& > svg {
		position: absolute;
		bottom: 0;
	}

	.Pricing--description {
		display: flex;

		position: relative;

		z-index: 10;

		& > * {
			flex: 1;
		}

		.Pricing--intro-text {
			padding: 60px 100px;
			box-sizing: border-box;
			position: relative;

			h1 {
				width: 100%;
				top: -30px;
				left: 0;

				text-align: center;

				font-size: 150px;

				display: inline-block;
				opacity: 0.05;

				position: absolute;
			}

			h2 {
				font-size: 40px;
				color: $inversedDefaultFontColor;
			}

			p {
				font-size: 20px;
				color: $inversedDefaultFontColor;
			}
		}

		.Pricing--plans {
			display: flex;

			justify-content: space-between;

			& > li {
				flex: 1;
			}
		}
	}

	&.Pricing--Elearnings {
		background-color: $green;

		ul.Pricing--SelectProduct {
			li {
				&.Pricing--SelectedProduct {
					color: $green;
				}
			}
		}

		& > svg {
			fill: $greenDarker;
		}
	}

	&.Pricing--Talents {
		background-color: $blue;

		ul.Pricing--SelectProduct {
			li {
				&.Pricing--SelectedProduct {
					color: $blue;
				}
			}
		}

		& > svg {
			fill: $blueDarker;
		}
	}

	&.Pricing--Challenges {
		background-color: $orange;

		ul.Pricing--SelectProduct {
			li {
				&.Pricing--SelectedProduct {
					color: $orange;
				}
			}
		}

		& > svg {
			fill: $orangeDarker;
		}
	}
}
