.MinimalElearningList {
	.list .item {
		background-color: white;
		@include small-box-shadow(#ccc);

		img {
			width: 100px;
			height: 100px;
			object-fit: cover;
		}
	}
}
