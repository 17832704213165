.Header {
	position: relative;
	z-index: 49;

	.item {
		&:before {
			width: 0 !important;
		}

		&.label-section {
			color: $defaultFontColor !important;
			border-bottom: 2px solid transparent !important;

			&.active {
				color: $primaryColor !important;
				background-color: transparent !important;
				border-bottom: 2px solid $primaryColor !important;
			}
		}
	}

	.Header--button-dropdown-searchers {
		p {
			font-size: 12px;
		}
	}

	.Header--dropdown-searchers {
		position: absolute;
		top: 75px;
		display: grid;
		background-color: white;
		grid-template-columns: 120px 120px 120px;

		@include small-box-shadow(#ddd);

		.item {
			justify-content: center;
			font-size: 12px;
			flex-direction: column;

			.icon {
				margin: 0;
				opacity: 0.4 !important;
			}

			&:hover,
			&.active {
				.icon {
					opacity: 1 !important;
				}
			}
		}

		.Header--dropdown-searchers-user {
			grid-column: 1 / 4;
		}
	}

	.Header--links {
		min-width: 120px;
		justify-content: center;
		font-size: 12px;
		flex-direction: column;

		.icon {
			margin: 0;
			opacity: 0.4 !important;

			&.Header--links-pointer {
				top: 7px;
				right: 40px;
				position: absolute;
				opacity: 1 !important;
			}
		}

		&:hover,
		&.active {
			.icon:not(.Header--links-pointer) {
				color: $primaryColor !important;
				opacity: 1 !important;
			}
		}
	}

	.right-menu {
		align-items: center;

		.item > .notification-label {
			left: auto;
			top: 1rem;
			right: 1rem;
			background-color: $primaryColor !important;
		}

		.icon {
			font-size: 1.3rem;
			color: $whityGrey;
		}

		.dropdown {
			@include fullWidthAbsolute();
			left: 0;
		}
	}

	.HeaderMobile {
		.right-menu {
			.SecondaryButton,
			.PrimaryButton {
				margin-left: 0;
				margin-right: 0;
			}
		}
	}

	/** HARDCODED **/
	.ui.avatar.image {
		width: 2rem !important;
	}

	.Header__business-profile-item {
		text-overflow: ellipsis;
		overflow: hidden;
	}
}

.HeaderMobile__WrapperRightMenu,
.HeaderMobile__WrapperRightMenuLoggedUserSidebar {
	.avatar.image {
		display: none !important;
	}
}

.HeaderMobile__WrapperRightMenuLoggedUser {
	.RightMenu_CreateBusinessProfileButton {
		display: none !important;
	}
}
