.UnitMarketplace {
	.ImageCarousel {
		width: 100%;
		text-align: center;
	}

	.WrapperUnitMarketplaceAboutCompany {
		overflow: hidden;
		background-color: rgba(0, 0, 0, 0.7);
		text-align: center;

		.UnitMarketplaceAboutCompany {
			img.UnitMarketplaceAboutCompany-profile-image {
				width: 100px;
				height: 100px;
				margin-bottom: 2rem;
				display: inline-block;
				object-fit: cover;
				vertical-align: middle;
				position: relative;
				z-index: 10;
				background-color: white;

				@include computer {
					margin-bottom: 0;
				}
			}

			img.UnitMarketplaceAboutCompany-background {
				width: 100%;
				height: calc(100% + 1.5rem);
				position: absolute;
				object-fit: cover;
				filter: blur(20px);
				top: -1.5rem;
				left: 0;
				z-index: -10;
			}

			.UnitMarketplaceAboutCompany-data {
				display: inline-block;
				vertical-align: middle;
				position: relative;
				z-index: 10;

				& > * {
					color: white;
				}

				a {
					font-size: 20px;
					display: block;
					font-weight: 600;
				}

				@include computer {
					margin-left: 2rem;
				}
			}
		}

		@include computer {
			text-align: left;
		}
	}

	.UnitMarketplace__definition {
		width: 100%;
		margin: 0 auto;
		padding: 0 30px;
		overflow: hidden;

		& > * {
			margin-bottom: 40px;
		}

		.button__already-applied {
			opacity: 1 !important;
		}

		.UnitMarketplace__description,
		.UnitMarketplaceOverview,
		.UnitMarketplaceTags,
		.UnitMarketplaceAboutCompany {
			padding: 0 0.5rem 10px;
			box-sizing: border-box;

			@include computer {
				padding: 0 40px 10px;
			}
		}

		.UnitMarketplaceOverview {
			h2 {
				margin-bottom: 0;
			}

			h4 {
				margin-top: 0;
				margin-bottom: 25px;
				margin-right: 10px;
				color: $whityGrey;
				display: inline-block;
			}

			.UnitMarketplaceOverview--wrapper-like {
				width: 40px;
				margin: 0 -20px;
			}

			b.marketplace-price-last {
				color: $whityWhiteGrey;
				display: block;
				font-family: $fontRegular;
				font-size: 12px;
				letter-spacing: 1px;
				text-decoration: line-through;
			}

			b.marketplace-price {
				color: $primaryColor;
				font-family: $fontSemiBold;
				font-size: 20px;
				letter-spacing: 1px;

				span {
					color: $green;
					font-size: 14px;
				}
			}
		}

		@include computer {
			width: 1000px;
		}
	}

	.UnitMarketplace__selection-process {
		width: 100%;
		min-height: 400px;
		padding: 0 60px;
		box-sizing: border-box;
		display: flex;
		overflow-x: scroll;

		.UnitMarketplace__unit-process {
			flex: 1 1 20%;
			margin: 10px;
			box-sizing: border-box;

			ul {
				padding: 0;

				li {
					margin-bottom: 10px;
				}
			}

			&:last-child {
				.card {
					margin-right: 60px;
				}
			}
		}
	}
}

.UnitMarketplaceHeader {
	padding: 2rem 0 0;
	text-align: center;

	a {
		width: 100%;
		max-width: 300px;
		margin: 0 auto;
		display: block;
		background-color: $grey;
		color: white;
		padding: 10px 3rem;
		transition: 0.3s;
		@include small-box-shadow(#ccc);

		&:hover {
			opacity: 0.6;
		}

		&.UnitMarketplaceHeader__edit-button {
			background-color: $green;
			margin-top: 20px;
		}

		&.UnitMarketplaceHeader__delete-button {
			background-color: $errorColor;
			margin-left: 20px;
		}

		@include computer {
			width: auto;
			display: inline;

			&.UnitMarketplaceHeader__edit-button {
				margin-left: 20px;
				margin-top: auto;
			}
		}
	}

	@include computer {
		padding: 3rem 0;
	}
}
