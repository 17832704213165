.Profile {
	.Profile__features-tabs {
		padding: 0 !important;
		box-shadow: 0 0 !important;
		background: none !important;

		& > .row {
			padding: 0 !important;
		}

		.ui.segment {
			border: 0 !important;
			box-shadow: none !important;
			background-color: transparent;
		}

		.ui.secondary.menu {
			padding: 1.5rem;
			margin: 0 0 2.5rem 0;
			overflow: hidden;

			.item {
				border-top: 2px solid transparent;
				border-bottom: 2px solid transparent;
				padding: 1.5rem;
				color: $whityGrey;
				font-family: $fontMedium;

				&.active {
					background-color: transparent;
					border-top: 2px solid rgba(0, 0, 0, 0.3);
					border-bottom: 2px solid rgba(0, 0, 0, 0.3);
					color: $defaultFontColor;
					border-radius: 0;
				}
			}
		}
	}

	.ChallengeTendersDashboardItem {
		.image img {
			width: 100%;
			height: 140px !important;
			object-fit: cover;
		}
	}

	@include computer {
		.Profile__features-column {
			padding-left: 4rem !important;
			padding-right: 4rem !important;
		}
	}
}
