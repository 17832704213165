.IntrapreneurshipLandscapeCard {
	$sideSquare: 100px;

	.IntrapreneurshipLandscapeCard--image {
		overflow: hidden;
		display: inline-block;
		vertical-align: middle;

		img {
			width: $sideSquare;
			height: $sideSquare !important;
			object-fit: cover;
		}
	}

	.IntrapreneurshipLandscapeCard--intro {
		width: auto !important;
		margin: 0 !important;
		display: inline-block;
		vertical-align: middle;
	}
}
