$sideSquare: 100px;

.TalentLandscapeCardDesktop {
	.TalentLandscapeCardDesktop--image {
		overflow: hidden;
		display: inline-block;
		vertical-align: middle;

		img {
			width: $sideSquare;
			height: $sideSquare !important;
			object-fit: cover;
		}
	}

	.TalentLandscapeCardDesktop--intro {
		width: auto !important;
		margin: 0 !important;
		display: inline-block;
		vertical-align: middle;
	}

	.TalentLandscapeCardDesktop__active-button {
		width: 100px;
		text-align: center;
		float: right;
	}

	.TalentLandscapeCardDesktop--features {
		width: 100%;
		display: flex;
		align-items: flex-end;

		& > * {
			flex: 3;
		}

		.TalentLandscapeCardDesktop--wrapper-like-button {
			flex: 0;
			margin-right: 20px;
			display: flex;
			align-items: center;
		}
	}
}

.TalentLandscapeCardMobile {
	.TalentLandscapeCardMobile--ClickableImage {
		width: 100%;

		img {
			height: 250px !important;
			object-fit: cover;
		}
	}
}
