.FormInfoBusinessProfile {
	.ui.labeled.input {
		flex-direction: column;

		input {
			width: 100% !important;
			border: 1px solid rgba(34, 36, 38, 0.15) !important;
		}
	}

	@include computer {
		.ui.labeled.input {
			flex-direction: row;

			input {
				width: auto !important;
				border: 1px solid rgba(34, 36, 38, 0.15) !important;
			}
		}

		.FormInfoBusinessProfile__InputFields {
			padding: 2rem;
		}
	}
}
