.SecondaryButton {
	&.ui.button {
		@include css3-prefix("box-shadow", "none!important");

		&.primary-color {
			background-color: $primaryColor;
		}

		&.secondary-color {
			background-color: $secondaryColor;
		}

		&.black-color {
			background-color: $defaultFontColor;
		}

		&.grey-color {
			background-color: $grey;
		}

		&.whity-grey-color {
			background-color: $whityGrey;
		}

		&:not(.inverted) {
			color: white;
		}

		&.inverted {
			background-color: #fff;
			border-width: 2px;
			border-style: solid;

			&.primary-color {
				color: $primaryColor;
				border-color: $primaryColor;
			}

			&.secondary-color {
				color: $secondaryColor;
				border-color: $secondaryColor;
			}

			&.black-color {
				color: $defaultFontColor;
				border-color: $defaultFontColor;
			}

			&.grey-color {
				color: $grey;
				border-color: $grey;
			}

			&.whity-grey-color {
				color: $whityGrey;
				border-color: $whityGrey;
			}
		}

		&.animated-button {
			@include transition("opacity 0.15s linear");

			&:hover {
				opacity: 0.6;
			}
		}
	}
}
