.WhiteWrapper {
	background-color: #fff;
	@include large-box-shadow(#e5e5e5);

	.white-wrapper-title {
		color: $grey;
	}

	.white-wrapper-action {
		min-height: 36px;
	}
}
