.ModernDatepicker {
	padding: 1rem !important;
	text-align: center;

	@apply border-gray-300 bg-gray-50 text-gray-900 #{!important};
}

.ModernGroupDatepicker {
	gap: 1rem;
}

.ModernDatepicker-disabled {
	background-color: $defaultFontColor;
}
