.ImagePicker {
	margin-top: 100px !important;

	&.bordered {
		border: 1px solid $whityWhiteGrey;
	}

	.image-picker-container {
		position: relative;
		margin-top: -100px;
	}

	.image-picker-button {
		border-radius: 100% !important;
		background-color: white !important;
		border: 1px solid $whityGrey !important;

		img {
			width: 150px;
			height: 150px;
		}
	}

	@include tablet {
		.image-picker-container {
			margin-top: -150px;
		}

		.image-picker-button {
			img {
				width: 200px;
				height: 200px;
			}
		}
	}
}
