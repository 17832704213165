.HeroImage {
	@include fullWidth;

	.background-image {
		@include fullWidth;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;

		img {
			@include imageBackground;
		}

		&.shadowy {
			img {
				@include css3-prefix("filter", "brightness(50%);");
			}
		}
	}

	.description {
		@include textBackground;
		@include fullWidth;
		display: inline-block;

		& > *.BOTTOM {
			bottom: 0;
		}

		& > *.RIGHT {
			right: 0;
		}
	}

	&.inversed * {
		color: $inversedDefaultFontColor;
	}
}
