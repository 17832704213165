.Pricing--feature {
	display: flex;
	align-items: flex-start;

	.Pricing--feature-icon {
		background-color: $backgroundColor;
		margin-right: 20px;
	}

	p {
		font-size: 16px !important;
	}
}
