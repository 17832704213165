.HowToSignAgreement {
	max-width: 800px;
	margin: 0 auto;

	li {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		span {
			width: 30px;
			height: 30px;

			margin-right: 30px;

			text-align: center;

			display: flex;
			align-items: center;
			justify-content: center;

			border-radius: 100%;

			background-color: $primaryColor;
			color: $white;
		}

		.HowToSignAgreement--step-description {
			flex: 1;
		}
	}
}
