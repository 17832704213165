.NewElearning {
	.DimmeableImage.ui.image {
		display: block;

		img {
			width: 100%;
			min-height: 250px;
			max-height: 250px;
			object-fit: cover;
		}
	}
}
