.CommonElearningCard {
	.CommonElearningCard__background-image {
		height: 150px !important;
		object-fit: cover;
	}

	.CommonElearningCard__title,
	.CommonElearningCard__description {
		text-overflow: ellipsis;
		overflow: hidden;
	}
}
